// Do not edit directly
// File generated from gatsby-node.js

export default {
      '/de/produkte/pura': '/pl/produkty/pura',
      '/de/produkte/verbindungswinkel-25-uni': '/pl/produkty/klamra-scienna-laczaca-prowadnice-25-uni',
      '/de/produkte/holzblende-montagewinkel-uni': '/pl/produkty/uchwyt-maskownicy-drewnianej-uni',
      '/de/produkte/montagewinkel-25-uni': '/pl/produkty/klamra-scienna-25-uni',
      '/de/produkte/alu-laufschiene-uni': '/pl/produkty/prowadnica-aluminiowa-uni',
      '/de/produkte/eloxierte-aluminiumblende-mit-kappen-45-mm-schwarz': '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-45-mm-czarna',
      '/de/produkte/eloxierte-aluminiumblende-mit-kappen-25-mm-schwarz': '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-25-mm-czarna',
      '/de/produkte/flache-aluminium-blende-schwarz': '/pl/produkty/maskownica-aluminiowa-plaska-czarna',
      '/de/produkte/griff-58-mm-schwarz': '/pl/produkty/uchwyt-58-mm-czarny',
      '/de/produkte/untere-fuhrung-fur-systeme-g80-g100': '/pl/produkty/prowadnik-dolny-g80-g100',
      '/de/produkte/turschliessdampfer-s120-g100': '/pl/produkty/domykacz-do-drzwi-przesuwnych-s120-g100',
      '/de/produkte/turschliessdampfer-s60-s100': '/pl/produkty/domykacz-do-drzwi-przesuwnych-s60-s100',
      '/de/produkte/loft-schiebesystem-variante-t-schwarz': '/pl/produkty/system-do-drzwi-przesuwnych-loft-t-czarny',
      '/de/produkte/w40-schiebetur-kit-fur-2-zusatzliche-turen': '/pl/produkty/w40-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-2-dodatkowe-skrzydla-drzwi',
      '/de/produkte/w40-schiebetur-kit-fur-1-zusatzliche-tur': '/pl/produkty/w40-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-1-dodatkowe-skrzydlo-drzwi',
      '/de/produkte/l30-schiebetur-kit-fur-zusatzliche-tur': '/pl/produkty/l30-system-do-lekkich-drzwi-przesuwnych-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/de/produkte/l40-schiebetur-kit-fur-zusatzliche-tur': '/pl/produkty/l40-system-do-lekkich-drzwi-przesuwnych-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/de/produkte/turschliessdampfer-w40': '/pl/produkty/domykacz-do-systemu-w40',
      '/de/produkte/w60-schiebetur-kit-fur-2-zusatzliche-turen': '/pl/produkty/w60-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-2-dodatkowe-skrzydla-drzwi',
      '/de/produkte/w60-schiebetur-kit-fur-1-zusatzliche-tur': '/pl/produkty/w60-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-1-dodatkowe-skrzydlo-drzwi',
      '/de/produkte/l30-system-fur-leichte-schiebeturen': '/pl/produkty/l30-system-do-lekkich-drzwi-przesuwnych',
      '/de/produkte/l40-system-fur-leichte-schiebeturen': '/pl/produkty/l40-system-do-lekkich-drzwi-przesuwnych',
      '/de/produkte/loft-schiebesystem-variante-karo-schwarz': '/pl/produkty/system-do-drzwi-przesuwnych-loft-karo-czarny',
      '/de/produkte/loft-schiebesystem-variante-u-schwarz': '/pl/produkty/system-do-drzwi-przesuwnych-loft-u-czarny',
      '/de/produkte/untere-fuhrung-fur-systeme-s60-s100-s120': '/pl/produkty/prowadnik-dolny-s60-s100-s120',
      '/de/produkte/flache-aluminium-blende': '/pl/produkty/maskownica-aluminiowa-plaska',
      '/de/produkte/abstandshalter': '/pl/produkty/tuleja-dystansowa',
      '/de/produkte/w60-schiebetursystem-fur-schrankturen-nischen': '/pl/produkty/w60-system-do-przesuwnych-drzwi-w-szafach-i-zabudowach-wnek',
      '/de/produkte/w40-schiebetursystem-fur-schrankturen-nischen': '/pl/produkty/w40-system-do-przesuwnych-drzwi-w-szafach-i-zabudowach-wnek',
      '/de/produkte/turgriff-03': '/pl/produkty/uchwyt-do-drzwi-przesuwnych-03',
      '/de/produkte/loft-turschliessdampfer-weiss': '/pl/produkty/domykacze-do-systemow-loft-bialych',
      '/de/produkte/loft-turschliessdampfer-schwarz': '/pl/produkty/domykacze-do-systemow-loft-czarnych',
      '/de/produkte/loft-schiebesystem-variante-v-schwarz': '/pl/produkty/system-do-drzwi-przesuwnych-loft-v-czarny',
      '/de/produkte/f40-laufwagen': '/pl/produkty/wozek-do-systemu-f40',
      '/de/produkte/f40-falttur-kit-fur-2-zusatzliche-paneele': '/pl/produkty/f40-system-bez-prowadnicy-na-dodatkowa-pare-drzwi',
      '/de/produkte/f25-laufwagen': '/pl/produkty/wozek-do-systemu-f25',
      '/de/produkte/f25-falttur-kit-fur-2-zusatzliche-paneele': '/pl/produkty/f25-system-bez-prowadnicy-na-dodatkowa-pare-drzwi',
      '/de/produkte/g100-schiebetur-kit-fur-zusatzliche-glastur': '/pl/produkty/g100-system-do-drzwi-przesuwnych-szklanych-bez-prowadnicy',
      '/de/produkte/g80-schiebetur-kit-fur-zusatzliche-glastur': '/pl/produkty/zestaw-g80-bez-prowadnicy',
      '/de/produkte/eloxierte-aluminiumblende-mit-kappen-45-mm': '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-45-mm',
      '/de/produkte/eloxierte-aluminiumblende-mit-kappen-25-mm': '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-25-mm',
      '/de/produkte/alu-laufschiene-s': '/pl/produkty/prowadnica-aluminiowa-s',
      '/de/produkte/griff-58-mm': '/pl/produkty/uchwyt-58-mm',
      '/de/produkte/loft-schiebesystem-variante-i-schwarz': '/pl/produkty/system-do-drzwi-przesuwnych-loft-i-czarny',
      '/de/produkte/g80-schiebetursystem-fur-glasturen': '/pl/produkty/g80-system-do-drzwi-przesuwnych-szklanych-na-1-skrzydlo-drzwi',
      '/de/produkte/g100-schiebetursystem-fur-glasturen': '/pl/produkty/g100-system-do-drzwi-przesuwnych-szklanych-na-1-skrzydlo-drzwi',
      '/de/produkte/f40-falttursystem-fur-2-paneele': '/pl/produkty/f40-system-do-drzwi-skladanych-na-1-pare-drzwi',
      '/de/produkte/f25-falttursystem-fur-2-paneele': '/pl/produkty/f25-system-do-drzwi-skladanych-na-1-pare-drzwi',
      '/de/produkte/s100-schiebetursystem-fur-2-turen': '/pl/produkty/s100-system-do-drzwi-przesuwnych-na-2-skrzydla-drzwi',
      '/de/produkte/s120-schiebetursystem-fur-2-turen': '/pl/produkty/s120-system-do-drzwi-przesuwnych-na-2-skrzydla-drzwi',
      '/de/produkte/s60-schiebetursystem-fur-2-turen': '/pl/produkty/s60-system-do-drzwi-przesuwnych-na-2-skrzydla-drzwi',
      '/de/produkte/s120-laufwagen': '/pl/produkty/wozek-do-systemu-s120',
      '/de/produkte/s100-laufwagen': '/pl/produkty/wozek-do-systemu-s100',
      '/de/produkte/s60-laufwagen': '/pl/produkty/wozek-do-systemu-s60',
      '/de/produkte/holzblende-montagewinkel-s': '/pl/produkty/uchwyt-maskownicy-drewnianej-s',
      '/de/produkte/verbindungswinkel-45-s': '/pl/produkty/klamra-scienna-laczaca-prowadnice-45-s',
      '/de/produkte/verbindungswinkel-25-s': '/pl/produkty/klamra-scienna-laczaca-prowadnice-25-s',
      '/de/produkte/montagewinkel-45-s': '/pl/produkty/klamra-scienna-45-s',
      '/de/produkte/montagewinkel-25-s': '/pl/produkty/klamra-scienna-25-s',
      '/de/produkte/s100-schiebetur-kit-fur-zusatzliche-tur': '/pl/produkty/s100-system-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/de/produkte/s120-schiebetur-kit-fur-zusatzliche-tur': '/pl/produkty/s120-system-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/de/produkte/s60-schiebetur-kit-fur-zusatzliche-tur': '/pl/produkty/s60-system-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/de/produkte/2s-synchronisationsbausatz': '/pl/produkty/2s-mechanizm-synchronicznego-otwierania-i-zamykania-drzwi',
      '/de/produkte/s100-schiebetursystem-fur-1-tur': '/pl/produkty/s100-system-do-drzwi-przesuwnych-na-1-skrzydlo-drzwi',
      '/de/produkte/s120-schiebetursystem-fur-1-tur': '/pl/produkty/s120-system-do-drzwi-przesuwnych-na-1-skrzydlo-drzwi',
      '/de/produkte/s60-schiebetursystem-fur-1-tur': '/pl/produkty/s60-system-do-drzwi-przesuwnych-na-1-skrzydlo-drzwi',
      '/de/imprint': '/pl/imprint',
      '/de/versand-und-zahlung': '/pl/koszty-dostawy',
      '/de/datenschutz': '/pl/polityka-prywatnosci',
      '/de/widerrufsbelehrung': '/pl/odstapienie-od-umowy',
      '/de/unternehmen': '/pl/o-firmie',
      '/de/agb': '/pl/regulamin',
      '/de/produkte': '/pl/produkty',
}
