// Do not edit directly
// File generated from gatsby-node.js

export default {
      '/de/produkte/pura': '/en/products/pura',
      '/de/produkte/verbindungswinkel-25-uni': '/en/products/connecting-bracket-25-uni',
      '/de/produkte/holzblende-montagewinkel-uni': '/en/products/wooden-fascia-bracket-uni',
      '/de/produkte/montagewinkel-25-uni': '/en/products/wall-fixing-bracket-25-uni',
      '/de/produkte/alu-laufschiene-uni': '/en/products/aluminium-track-uni',
      '/de/produkte/eloxierte-aluminiumblende-mit-kappen-45-mm-schwarz': '/en/products/anodized-aluminium-fascia-with-end-caps-45-mm-black',
      '/de/produkte/eloxierte-aluminiumblende-mit-kappen-25-mm-schwarz': '/en/products/anodized-aluminium-fascia-with-end-caps-25-mm-black',
      '/de/produkte/flache-aluminium-blende-schwarz': '/en/products/flat-aluminium-fascia-black',
      '/de/produkte/griff-58-mm-schwarz': '/en/products/handle-58-mm-black',
      '/de/produkte/untere-fuhrung-fur-systeme-g80-g100': '/en/products/lower-guide-for-g80-and-g100-systems',
      '/de/produkte/turschliessdampfer-s120-g100': '/en/products/soft-close-damper-s120-g100',
      '/de/produkte/turschliessdampfer-s60-s100': '/en/products/soft-close-damper-s60-s100',
      '/de/produkte/loft-schiebesystem-variante-t-schwarz': '/en/products/loft-sliding-system-t-black',
      '/de/produkte/w40-schiebetur-kit-fur-2-zusatzliche-turen': '/en/products/w40-sliding-system-kit-for-2-additional-door-leaves',
      '/de/produkte/w40-schiebetur-kit-fur-1-zusatzliche-tur': '/en/products/w40-sliding-system-kit-for-1-additional-door-leaf',
      '/de/produkte/l30-schiebetur-kit-fur-zusatzliche-tur': '/en/products/l30-sliding-system-kit-for-additional-door-leaf',
      '/de/produkte/l40-schiebetur-kit-fur-zusatzliche-tur': '/en/products/l40-sliding-system-kit-for-additional-door-leaf',
      '/de/produkte/turschliessdampfer-w40': '/en/products/soft-close-damper-w40',
      '/de/produkte/w60-schiebetur-kit-fur-2-zusatzliche-turen': '/en/products/w60-sliding-system-kit-for-2-additional-door-leaves',
      '/de/produkte/w60-schiebetur-kit-fur-1-zusatzliche-tur': '/en/products/w60-sliding-system-kit-for-1-additional-door-leaf',
      '/de/produkte/l30-system-fur-leichte-schiebeturen': '/en/products/l30-system-for-lightweight-sliding-doors',
      '/de/produkte/l40-system-fur-leichte-schiebeturen': '/en/products/l40-system-for-lightweight-sliding-doors',
      '/de/produkte/loft-schiebesystem-variante-karo-schwarz': '/en/products/loft-sliding-system-karo-black',
      '/de/produkte/loft-schiebesystem-variante-u-schwarz': '/en/products/loft-sliding-system-u-black',
      '/de/produkte/untere-fuhrung-fur-systeme-s60-s100-s120': '/en/products/lower-guide-for-s60-s100-s120-systems',
      '/de/produkte/flache-aluminium-blende': '/en/products/flat-aluminium-fascia',
      '/de/produkte/abstandshalter': '/en/products/spacer',
      '/de/produkte/w60-schiebetursystem-fur-schrankturen-nischen': '/en/products/w60-sliding-system-for-closet-wardrobe-doors',
      '/de/produkte/w40-schiebetursystem-fur-schrankturen-nischen': '/en/products/w40-sliding-system-for-closet-wardrobe-doors',
      '/de/produkte/turgriff-03': '/en/products/door-handle-03',
      '/de/produkte/loft-turschliessdampfer-weiss': '/en/products/loft-soft-close-dampers-white',
      '/de/produkte/loft-turschliessdampfer-schwarz': '/en/products/loft-soft-close-dampers-black',
      '/de/produkte/loft-schiebesystem-variante-v-schwarz': '/en/products/loft-sliding-system-v-black',
      '/de/produkte/f40-laufwagen': '/en/products/f40-roller',
      '/de/produkte/f40-falttur-kit-fur-2-zusatzliche-paneele': '/en/products/f40-folding-door-kit-for-2-additional-panels',
      '/de/produkte/f25-laufwagen': '/en/products/f25-roller',
      '/de/produkte/f25-falttur-kit-fur-2-zusatzliche-paneele': '/en/products/f25-folding-door-kit-for-2-additional-panels',
      '/de/produkte/g100-schiebetur-kit-fur-zusatzliche-glastur': '/en/products/g100-sliding-door-kit-for-additional-glass-door',
      '/de/produkte/g80-schiebetur-kit-fur-zusatzliche-glastur': '/en/products/g80-sliding-door-kit-for-additional-glass-door',
      '/de/produkte/eloxierte-aluminiumblende-mit-kappen-45-mm': '/en/products/anodized-aluminium-fascia-with-end-caps-45-mm',
      '/de/produkte/eloxierte-aluminiumblende-mit-kappen-25-mm': '/en/products/anodized-aluminium-fascia-with-end-caps-25-mm',
      '/de/produkte/alu-laufschiene-s': '/en/products/aluminium-track-s',
      '/de/produkte/griff-58-mm': '/en/products/handle-58-mm',
      '/de/produkte/loft-schiebesystem-variante-i-schwarz': '/en/products/loft-sliding-system-i-black',
      '/de/produkte/g80-schiebetursystem-fur-glasturen': '/en/products/g80-sliding-door-system-for-glass-door',
      '/de/produkte/g100-schiebetursystem-fur-glasturen': '/en/products/g100-sliding-door-system-for-glass-door',
      '/de/produkte/f40-falttursystem-fur-2-paneele': '/en/products/f40-folding-door-system-for-2-panels',
      '/de/produkte/f25-falttursystem-fur-2-paneele': '/en/products/f25-folding-door-system-for-2-panels',
      '/de/produkte/s100-schiebetursystem-fur-2-turen': '/en/products/s100-sliding-door-system-for-two-doors',
      '/de/produkte/s120-schiebetursystem-fur-2-turen': '/en/products/s120-sliding-door-system-for-two-doors',
      '/de/produkte/s60-schiebetursystem-fur-2-turen': '/en/products/s60-sliding-door-system-for-two-doors',
      '/de/produkte/s120-laufwagen': '/en/products/s120-roller',
      '/de/produkte/s100-laufwagen': '/en/products/s100-roller',
      '/de/produkte/s60-laufwagen': '/en/products/s60-roller',
      '/de/produkte/holzblende-montagewinkel-s': '/en/products/wooden-fascia-bracket-s',
      '/de/produkte/verbindungswinkel-45-s': '/en/products/connecting-bracket-45-s',
      '/de/produkte/verbindungswinkel-25-s': '/en/products/connecting-bracket-25-s',
      '/de/produkte/montagewinkel-45-s': '/en/products/wall-fixing-bracket-45-s',
      '/de/produkte/montagewinkel-25-s': '/en/products/wall-fixing-bracket-25-s',
      '/de/produkte/s100-schiebetur-kit-fur-zusatzliche-tur': '/en/products/s100-sliding-system-kit-for-additional-door-leaf',
      '/de/produkte/s120-schiebetur-kit-fur-zusatzliche-tur': '/en/products/s120-sliding-system-kit-for-additional-door-leaf',
      '/de/produkte/s60-schiebetur-kit-fur-zusatzliche-tur': '/en/products/s60-sliding-system-kit-for-additional-door-leaf',
      '/de/produkte/2s-synchronisationsbausatz': '/en/products/2s-synchronization-kit',
      '/de/produkte/s100-schiebetursystem-fur-1-tur': '/en/products/s100-sliding-door-system-for-one-door',
      '/de/produkte/s120-schiebetursystem-fur-1-tur': '/en/products/s120-sliding-door-system-for-one-door',
      '/de/produkte/s60-schiebetursystem-fur-1-tur': '/en/products/s60-sliding-door-system-for-one-door',
      '/de/imprint': '/en/imprint',
      '/de/versand-und-zahlung': '/en/shipping-and-payment',
      '/de/datenschutz': '/en/data-protection',
      '/de/widerrufsbelehrung': '/en/cancellation',
      '/de/unternehmen': '/en/company',
      '/de/agb': '/en/terms-and-conditions',
      '/de/produkte': '/en/products',
}
