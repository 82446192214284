// Do not edit directly
// File generated from gatsby-node.js

export default {
      '/en/products/pura': '/nl/producten/pura',
      '/en/products/connecting-bracket-25-uni': '/nl/producten/verbindingsbeugel-25-uni',
      '/en/products/wooden-fascia-bracket-uni': '/nl/producten/houten-lateihaak-uni',
      '/en/products/wall-fixing-bracket-25-uni': '/nl/producten/muurbevestigingssteun-25-uni',
      '/en/products/aluminium-track-uni': '/nl/producten/aluminium-rail-uni',
      '/en/products/anodized-aluminium-fascia-with-end-caps-45-mm-black': '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-45-mm-zwart',
      '/en/products/anodized-aluminium-fascia-with-end-caps-25-mm-black': '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-25-mm-zwart',
      '/en/products/flat-aluminium-fascia-black': '/nl/producten/plat-aluminium-latei-zwart',
      '/en/products/handle-58-mm-black': '/nl/producten/handgreep-58-mm-zwart',
      '/en/products/lower-guide-for-g80-and-g100-systems': '/nl/producten/ondergeleider-voor-systemen-g80-g100',
      '/en/products/soft-close-damper-s120-g100': '/nl/producten/zachte-sluitdemper-voor-s120-g100',
      '/en/products/soft-close-damper-s60-s100': '/nl/producten/zachte-sluitdemper-voor-s60-s100',
      '/en/products/loft-sliding-system-t-black': '/nl/producten/loft-t-zwart-schuifdeursysteem',
      '/en/products/w40-sliding-system-kit-for-2-additional-door-leaves': '/nl/producten/w40-sliding-system-kit-for-2-additional-door-leaves',
      '/en/products/w40-sliding-system-kit-for-1-additional-door-leaf': '/nl/producten/w40-sliding-system-kit-for-1-additional-door-leaf',
      '/en/products/l30-sliding-system-kit-for-additional-door-leaf': '/nl/producten/l30-sliding-system-kit-for-additional-door-leaf',
      '/en/products/l40-sliding-system-kit-for-additional-door-leaf': '/nl/producten/l40-sliding-system-kit-for-additional-door-leaf',
      '/en/products/soft-close-damper-w40': '/nl/producten/soft-close-damper-w40',
      '/en/products/w60-sliding-system-kit-for-2-additional-door-leaves': '/nl/producten/w60-sliding-system-kit-for-2-additional-door-leaves',
      '/en/products/w60-sliding-system-kit-for-1-additional-door-leaf': '/nl/producten/w60-sliding-system-kit-for-1-additional-door-leaf',
      '/en/products/l30-system-for-lightweight-sliding-doors': '/nl/producten/l30-system-for-lightweight-sliding-doors',
      '/en/products/l40-system-for-lightweight-sliding-doors': '/nl/producten/l40-system-for-lightweight-sliding-doors',
      '/en/products/loft-sliding-system-karo-black': '/nl/producten/loft-karo-zwart-schuifdeursysteem',
      '/en/products/loft-sliding-system-u-black': '/nl/producten/loft-u-zwart-schuifdeursysteem',
      '/en/products/lower-guide-for-s60-s100-s120-systems': '/nl/producten/ondergeleider-voor-systemen-s60-s100-s120',
      '/en/products/flat-aluminium-fascia': '/nl/producten/plat-aluminium-latei',
      '/en/products/spacer': '/nl/producten/afstandshuls',
      '/en/products/w60-sliding-system-for-closet-wardrobe-doors': '/nl/producten/w60-schuifsysteem-voor-kast-kleerkastdeuren',
      '/en/products/w40-sliding-system-for-closet-wardrobe-doors': '/nl/producten/w40-schuifsysteem-voor-kast-kleerkastdeuren',
      '/en/products/door-handle-03': '/nl/producten/handgreep-schuifdeur-03',
      '/en/products/loft-soft-close-dampers-white': '/nl/producten/loft-zachte-sluitdempers-wit',
      '/en/products/loft-soft-close-dampers-black': '/nl/producten/loft-zachte-sluitdempers-zwart',
      '/en/products/loft-sliding-system-v-black': '/nl/producten/loft-v-zwart-schuifdeursysteem',
      '/en/products/f40-roller': '/nl/producten/f40-roller',
      '/en/products/f40-folding-door-kit-for-2-additional-panels': '/nl/producten/f40-vouwdeursysteemkit-voor-2-extra-panelen',
      '/en/products/f25-roller': '/nl/producten/f25-roller',
      '/en/products/f25-folding-door-kit-for-2-additional-panels': '/nl/producten/f25-vouwdeursysteemkit-voor-2-extra-panelen',
      '/en/products/g100-sliding-door-kit-for-additional-glass-door': '/nl/producten/g100-schuifdeursysteemkit-voor-extra-glazen-deuren',
      '/en/products/g80-sliding-door-kit-for-additional-glass-door': '/nl/producten/g80-schuifdeursysteemkit-voor-extra-glazen-deuren',
      '/en/products/anodized-aluminium-fascia-with-end-caps-45-mm': '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-45-mm',
      '/en/products/anodized-aluminium-fascia-with-end-caps-25-mm': '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-25-mm',
      '/en/products/aluminium-track-s': '/nl/producten/aluminium-rail-s',
      '/en/products/handle-58-mm': '/nl/producten/handgreep-58-mm',
      '/en/products/loft-sliding-system-i-black': '/nl/producten/loft-i-zwart-schuifdeursysteem',
      '/en/products/g80-sliding-door-system-for-glass-door': '/nl/producten/g80-schuifdeursysteem-voor-glazen-deuren',
      '/en/products/g100-sliding-door-system-for-glass-door': '/nl/producten/g100-schuifdeursysteem-voor-glazen-deuren',
      '/en/products/f40-folding-door-system-for-2-panels': '/nl/producten/f40-vouwdeursysteem-voor-twee-panelen',
      '/en/products/f25-folding-door-system-for-2-panels': '/nl/producten/f25-vouwdeursysteem-voor-twee-panelen',
      '/en/products/s100-sliding-door-system-for-two-doors': '/nl/producten/s100-schuifdeursysteem-voor-twee-deuren',
      '/en/products/s120-sliding-door-system-for-two-doors': '/nl/producten/s120-schuifdeursysteem-voor-twee-deuren',
      '/en/products/s60-sliding-door-system-for-two-doors': '/nl/producten/s60-schuifdeursysteem-voor-twee-deuren',
      '/en/products/s120-roller': '/nl/producten/s120-roller',
      '/en/products/s100-roller': '/nl/producten/s100-roller',
      '/en/products/s60-roller': '/nl/producten/s60-roller',
      '/en/products/wooden-fascia-bracket-s': '/nl/producten/houten-lateihaak-s',
      '/en/products/connecting-bracket-45-s': '/nl/producten/verbindingsbeugel-45-s',
      '/en/products/connecting-bracket-25-s': '/nl/producten/verbindingsbeugel-25-s',
      '/en/products/wall-fixing-bracket-45-s': '/nl/producten/muurbevestigingssteun-45-s',
      '/en/products/wall-fixing-bracket-25-s': '/nl/producten/muurbevestigingssteun-25-s',
      '/en/products/s100-sliding-system-kit-for-additional-door-leaf': '/nl/producten/s100-schuifdeursysteemkit-voor-extra-deuren',
      '/en/products/s120-sliding-system-kit-for-additional-door-leaf': '/nl/producten/s120-schuifdeursysteemkit-voor-extra-deuren',
      '/en/products/s60-sliding-system-kit-for-additional-door-leaf': '/nl/producten/s60-schuifdeursysteemkit-voor-extra-deuren',
      '/en/products/2s-synchronization-kit': '/nl/producten/2s-synchronisatiekit',
      '/en/products/s100-sliding-door-system-for-one-door': '/nl/producten/s100-schuifdeursysteem-voor-een-deur',
      '/en/products/s120-sliding-door-system-for-one-door': '/nl/producten/s120-schuifdeursysteem-voor-een-deur',
      '/en/products/s60-sliding-door-system-for-one-door': '/nl/producten/s60-schuifdeursysteem-voor-een-deur',
      '/en/imprint': '/nl/imprint',
      '/en/shipping-and-payment': '/nl/verzending-en-betaling',
      '/en/data-protection': '/nl/data-protection',
      '/en/cancellation': '/nl/cancellation',
      '/en/company': '/nl/bedrijf',
      '/en/terms-and-conditions': '/nl/terms-and-conditions',
      '/en/products': '/nl/producten',
}
