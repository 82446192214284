// Do not edit directly
// File generated from gatsby-node.js

export default {
      '/en/products/pura': '/en/products/pura',
      '/en/products/connecting-bracket-25-uni': '/en/products/connecting-bracket-25-uni',
      '/en/products/wooden-fascia-bracket-uni': '/en/products/wooden-fascia-bracket-uni',
      '/en/products/wall-fixing-bracket-25-uni': '/en/products/wall-fixing-bracket-25-uni',
      '/en/products/aluminium-track-uni': '/en/products/aluminium-track-uni',
      '/en/products/anodized-aluminium-fascia-with-end-caps-45-mm-black': '/en/products/anodized-aluminium-fascia-with-end-caps-45-mm-black',
      '/en/products/anodized-aluminium-fascia-with-end-caps-25-mm-black': '/en/products/anodized-aluminium-fascia-with-end-caps-25-mm-black',
      '/en/products/flat-aluminium-fascia-black': '/en/products/flat-aluminium-fascia-black',
      '/en/products/handle-58-mm-black': '/en/products/handle-58-mm-black',
      '/en/products/lower-guide-for-g80-and-g100-systems': '/en/products/lower-guide-for-g80-and-g100-systems',
      '/en/products/soft-close-damper-s120-g100': '/en/products/soft-close-damper-s120-g100',
      '/en/products/soft-close-damper-s60-s100': '/en/products/soft-close-damper-s60-s100',
      '/en/products/loft-sliding-system-t-black': '/en/products/loft-sliding-system-t-black',
      '/en/products/w40-sliding-system-kit-for-2-additional-door-leaves': '/en/products/w40-sliding-system-kit-for-2-additional-door-leaves',
      '/en/products/w40-sliding-system-kit-for-1-additional-door-leaf': '/en/products/w40-sliding-system-kit-for-1-additional-door-leaf',
      '/en/products/l30-sliding-system-kit-for-additional-door-leaf': '/en/products/l30-sliding-system-kit-for-additional-door-leaf',
      '/en/products/l40-sliding-system-kit-for-additional-door-leaf': '/en/products/l40-sliding-system-kit-for-additional-door-leaf',
      '/en/products/soft-close-damper-w40': '/en/products/soft-close-damper-w40',
      '/en/products/w60-sliding-system-kit-for-2-additional-door-leaves': '/en/products/w60-sliding-system-kit-for-2-additional-door-leaves',
      '/en/products/w60-sliding-system-kit-for-1-additional-door-leaf': '/en/products/w60-sliding-system-kit-for-1-additional-door-leaf',
      '/en/products/l30-system-for-lightweight-sliding-doors': '/en/products/l30-system-for-lightweight-sliding-doors',
      '/en/products/l40-system-for-lightweight-sliding-doors': '/en/products/l40-system-for-lightweight-sliding-doors',
      '/en/products/loft-sliding-system-karo-black': '/en/products/loft-sliding-system-karo-black',
      '/en/products/loft-sliding-system-u-black': '/en/products/loft-sliding-system-u-black',
      '/en/products/lower-guide-for-s60-s100-s120-systems': '/en/products/lower-guide-for-s60-s100-s120-systems',
      '/en/products/flat-aluminium-fascia': '/en/products/flat-aluminium-fascia',
      '/en/products/spacer': '/en/products/spacer',
      '/en/products/w60-sliding-system-for-closet-wardrobe-doors': '/en/products/w60-sliding-system-for-closet-wardrobe-doors',
      '/en/products/w40-sliding-system-for-closet-wardrobe-doors': '/en/products/w40-sliding-system-for-closet-wardrobe-doors',
      '/en/products/door-handle-03': '/en/products/door-handle-03',
      '/en/products/loft-soft-close-dampers-white': '/en/products/loft-soft-close-dampers-white',
      '/en/products/loft-soft-close-dampers-black': '/en/products/loft-soft-close-dampers-black',
      '/en/products/loft-sliding-system-v-black': '/en/products/loft-sliding-system-v-black',
      '/en/products/f40-roller': '/en/products/f40-roller',
      '/en/products/f40-folding-door-kit-for-2-additional-panels': '/en/products/f40-folding-door-kit-for-2-additional-panels',
      '/en/products/f25-roller': '/en/products/f25-roller',
      '/en/products/f25-folding-door-kit-for-2-additional-panels': '/en/products/f25-folding-door-kit-for-2-additional-panels',
      '/en/products/g100-sliding-door-kit-for-additional-glass-door': '/en/products/g100-sliding-door-kit-for-additional-glass-door',
      '/en/products/g80-sliding-door-kit-for-additional-glass-door': '/en/products/g80-sliding-door-kit-for-additional-glass-door',
      '/en/products/anodized-aluminium-fascia-with-end-caps-45-mm': '/en/products/anodized-aluminium-fascia-with-end-caps-45-mm',
      '/en/products/anodized-aluminium-fascia-with-end-caps-25-mm': '/en/products/anodized-aluminium-fascia-with-end-caps-25-mm',
      '/en/products/aluminium-track-s': '/en/products/aluminium-track-s',
      '/en/products/handle-58-mm': '/en/products/handle-58-mm',
      '/en/products/loft-sliding-system-i-black': '/en/products/loft-sliding-system-i-black',
      '/en/products/g80-sliding-door-system-for-glass-door': '/en/products/g80-sliding-door-system-for-glass-door',
      '/en/products/g100-sliding-door-system-for-glass-door': '/en/products/g100-sliding-door-system-for-glass-door',
      '/en/products/f40-folding-door-system-for-2-panels': '/en/products/f40-folding-door-system-for-2-panels',
      '/en/products/f25-folding-door-system-for-2-panels': '/en/products/f25-folding-door-system-for-2-panels',
      '/en/products/s100-sliding-door-system-for-two-doors': '/en/products/s100-sliding-door-system-for-two-doors',
      '/en/products/s120-sliding-door-system-for-two-doors': '/en/products/s120-sliding-door-system-for-two-doors',
      '/en/products/s60-sliding-door-system-for-two-doors': '/en/products/s60-sliding-door-system-for-two-doors',
      '/en/products/s120-roller': '/en/products/s120-roller',
      '/en/products/s100-roller': '/en/products/s100-roller',
      '/en/products/s60-roller': '/en/products/s60-roller',
      '/en/products/wooden-fascia-bracket-s': '/en/products/wooden-fascia-bracket-s',
      '/en/products/connecting-bracket-45-s': '/en/products/connecting-bracket-45-s',
      '/en/products/connecting-bracket-25-s': '/en/products/connecting-bracket-25-s',
      '/en/products/wall-fixing-bracket-45-s': '/en/products/wall-fixing-bracket-45-s',
      '/en/products/wall-fixing-bracket-25-s': '/en/products/wall-fixing-bracket-25-s',
      '/en/products/s100-sliding-system-kit-for-additional-door-leaf': '/en/products/s100-sliding-system-kit-for-additional-door-leaf',
      '/en/products/s120-sliding-system-kit-for-additional-door-leaf': '/en/products/s120-sliding-system-kit-for-additional-door-leaf',
      '/en/products/s60-sliding-system-kit-for-additional-door-leaf': '/en/products/s60-sliding-system-kit-for-additional-door-leaf',
      '/en/products/2s-synchronization-kit': '/en/products/2s-synchronization-kit',
      '/en/products/s100-sliding-door-system-for-one-door': '/en/products/s100-sliding-door-system-for-one-door',
      '/en/products/s120-sliding-door-system-for-one-door': '/en/products/s120-sliding-door-system-for-one-door',
      '/en/products/s60-sliding-door-system-for-one-door': '/en/products/s60-sliding-door-system-for-one-door',
      '/en/imprint': '/en/imprint',
      '/en/shipping-and-payment': '/en/shipping-and-payment',
      '/en/data-protection': '/en/data-protection',
      '/en/cancellation': '/en/cancellation',
      '/en/company': '/en/company',
      '/en/terms-and-conditions': '/en/terms-and-conditions',
      '/en/products': '/en/products',
}
