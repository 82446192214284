// Do not edit directly
// File generated from gatsby-node.js

export default {
      '/nl/producten/pura': '/pl/produkty/pura',
      '/nl/producten/verbindingsbeugel-25-uni': '/pl/produkty/klamra-scienna-laczaca-prowadnice-25-uni',
      '/nl/producten/houten-lateihaak-uni': '/pl/produkty/uchwyt-maskownicy-drewnianej-uni',
      '/nl/producten/muurbevestigingssteun-25-uni': '/pl/produkty/klamra-scienna-25-uni',
      '/nl/producten/aluminium-rail-uni': '/pl/produkty/prowadnica-aluminiowa-uni',
      '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-45-mm-zwart': '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-45-mm-czarna',
      '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-25-mm-zwart': '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-25-mm-czarna',
      '/nl/producten/plat-aluminium-latei-zwart': '/pl/produkty/maskownica-aluminiowa-plaska-czarna',
      '/nl/producten/handgreep-58-mm-zwart': '/pl/produkty/uchwyt-58-mm-czarny',
      '/nl/producten/ondergeleider-voor-systemen-g80-g100': '/pl/produkty/prowadnik-dolny-g80-g100',
      '/nl/producten/zachte-sluitdemper-voor-s120-g100': '/pl/produkty/domykacz-do-drzwi-przesuwnych-s120-g100',
      '/nl/producten/zachte-sluitdemper-voor-s60-s100': '/pl/produkty/domykacz-do-drzwi-przesuwnych-s60-s100',
      '/nl/producten/loft-t-zwart-schuifdeursysteem': '/pl/produkty/system-do-drzwi-przesuwnych-loft-t-czarny',
      '/nl/producten/w40-sliding-system-kit-for-2-additional-door-leaves': '/pl/produkty/w40-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-2-dodatkowe-skrzydla-drzwi',
      '/nl/producten/w40-sliding-system-kit-for-1-additional-door-leaf': '/pl/produkty/w40-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-1-dodatkowe-skrzydlo-drzwi',
      '/nl/producten/l30-sliding-system-kit-for-additional-door-leaf': '/pl/produkty/l30-system-do-lekkich-drzwi-przesuwnych-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/nl/producten/l40-sliding-system-kit-for-additional-door-leaf': '/pl/produkty/l40-system-do-lekkich-drzwi-przesuwnych-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/nl/producten/soft-close-damper-w40': '/pl/produkty/domykacz-do-systemu-w40',
      '/nl/producten/w60-sliding-system-kit-for-2-additional-door-leaves': '/pl/produkty/w60-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-2-dodatkowe-skrzydla-drzwi',
      '/nl/producten/w60-sliding-system-kit-for-1-additional-door-leaf': '/pl/produkty/w60-system-do-przesuwnych-drzwi-w-szafach-bez-prowadnicy-na-1-dodatkowe-skrzydlo-drzwi',
      '/nl/producten/l30-system-for-lightweight-sliding-doors': '/pl/produkty/l30-system-do-lekkich-drzwi-przesuwnych',
      '/nl/producten/l40-system-for-lightweight-sliding-doors': '/pl/produkty/l40-system-do-lekkich-drzwi-przesuwnych',
      '/nl/producten/loft-karo-zwart-schuifdeursysteem': '/pl/produkty/system-do-drzwi-przesuwnych-loft-karo-czarny',
      '/nl/producten/loft-u-zwart-schuifdeursysteem': '/pl/produkty/system-do-drzwi-przesuwnych-loft-u-czarny',
      '/nl/producten/ondergeleider-voor-systemen-s60-s100-s120': '/pl/produkty/prowadnik-dolny-s60-s100-s120',
      '/nl/producten/plat-aluminium-latei': '/pl/produkty/maskownica-aluminiowa-plaska',
      '/nl/producten/afstandshuls': '/pl/produkty/tuleja-dystansowa',
      '/nl/producten/w60-schuifsysteem-voor-kast-kleerkastdeuren': '/pl/produkty/w60-system-do-przesuwnych-drzwi-w-szafach-i-zabudowach-wnek',
      '/nl/producten/w40-schuifsysteem-voor-kast-kleerkastdeuren': '/pl/produkty/w40-system-do-przesuwnych-drzwi-w-szafach-i-zabudowach-wnek',
      '/nl/producten/handgreep-schuifdeur-03': '/pl/produkty/uchwyt-do-drzwi-przesuwnych-03',
      '/nl/producten/loft-zachte-sluitdempers-wit': '/pl/produkty/domykacze-do-systemow-loft-bialych',
      '/nl/producten/loft-zachte-sluitdempers-zwart': '/pl/produkty/domykacze-do-systemow-loft-czarnych',
      '/nl/producten/loft-v-zwart-schuifdeursysteem': '/pl/produkty/system-do-drzwi-przesuwnych-loft-v-czarny',
      '/nl/producten/f40-roller': '/pl/produkty/wozek-do-systemu-f40',
      '/nl/producten/f40-vouwdeursysteemkit-voor-2-extra-panelen': '/pl/produkty/f40-system-bez-prowadnicy-na-dodatkowa-pare-drzwi',
      '/nl/producten/f25-roller': '/pl/produkty/wozek-do-systemu-f25',
      '/nl/producten/f25-vouwdeursysteemkit-voor-2-extra-panelen': '/pl/produkty/f25-system-bez-prowadnicy-na-dodatkowa-pare-drzwi',
      '/nl/producten/g100-schuifdeursysteemkit-voor-extra-glazen-deuren': '/pl/produkty/g100-system-do-drzwi-przesuwnych-szklanych-bez-prowadnicy',
      '/nl/producten/g80-schuifdeursysteemkit-voor-extra-glazen-deuren': '/pl/produkty/zestaw-g80-bez-prowadnicy',
      '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-45-mm': '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-45-mm',
      '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-25-mm': '/pl/produkty/maskownica-aluminiowa-z-zaslepkami-bocznymi-25-mm',
      '/nl/producten/aluminium-rail-s': '/pl/produkty/prowadnica-aluminiowa-s',
      '/nl/producten/handgreep-58-mm': '/pl/produkty/uchwyt-58-mm',
      '/nl/producten/loft-i-zwart-schuifdeursysteem': '/pl/produkty/system-do-drzwi-przesuwnych-loft-i-czarny',
      '/nl/producten/g80-schuifdeursysteem-voor-glazen-deuren': '/pl/produkty/g80-system-do-drzwi-przesuwnych-szklanych-na-1-skrzydlo-drzwi',
      '/nl/producten/g100-schuifdeursysteem-voor-glazen-deuren': '/pl/produkty/g100-system-do-drzwi-przesuwnych-szklanych-na-1-skrzydlo-drzwi',
      '/nl/producten/f40-vouwdeursysteem-voor-twee-panelen': '/pl/produkty/f40-system-do-drzwi-skladanych-na-1-pare-drzwi',
      '/nl/producten/f25-vouwdeursysteem-voor-twee-panelen': '/pl/produkty/f25-system-do-drzwi-skladanych-na-1-pare-drzwi',
      '/nl/producten/s100-schuifdeursysteem-voor-twee-deuren': '/pl/produkty/s100-system-do-drzwi-przesuwnych-na-2-skrzydla-drzwi',
      '/nl/producten/s120-schuifdeursysteem-voor-twee-deuren': '/pl/produkty/s120-system-do-drzwi-przesuwnych-na-2-skrzydla-drzwi',
      '/nl/producten/s60-schuifdeursysteem-voor-twee-deuren': '/pl/produkty/s60-system-do-drzwi-przesuwnych-na-2-skrzydla-drzwi',
      '/nl/producten/s120-roller': '/pl/produkty/wozek-do-systemu-s120',
      '/nl/producten/s100-roller': '/pl/produkty/wozek-do-systemu-s100',
      '/nl/producten/s60-roller': '/pl/produkty/wozek-do-systemu-s60',
      '/nl/producten/houten-lateihaak-s': '/pl/produkty/uchwyt-maskownicy-drewnianej-s',
      '/nl/producten/verbindingsbeugel-45-s': '/pl/produkty/klamra-scienna-laczaca-prowadnice-45-s',
      '/nl/producten/verbindingsbeugel-25-s': '/pl/produkty/klamra-scienna-laczaca-prowadnice-25-s',
      '/nl/producten/muurbevestigingssteun-45-s': '/pl/produkty/klamra-scienna-45-s',
      '/nl/producten/muurbevestigingssteun-25-s': '/pl/produkty/klamra-scienna-25-s',
      '/nl/producten/s100-schuifdeursysteemkit-voor-extra-deuren': '/pl/produkty/s100-system-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/nl/producten/s120-schuifdeursysteemkit-voor-extra-deuren': '/pl/produkty/s120-system-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/nl/producten/s60-schuifdeursysteemkit-voor-extra-deuren': '/pl/produkty/s60-system-bez-prowadnicy-na-dodatkowe-skrzydlo-drzwi',
      '/nl/producten/2s-synchronisatiekit': '/pl/produkty/2s-mechanizm-synchronicznego-otwierania-i-zamykania-drzwi',
      '/nl/producten/s100-schuifdeursysteem-voor-een-deur': '/pl/produkty/s100-system-do-drzwi-przesuwnych-na-1-skrzydlo-drzwi',
      '/nl/producten/s120-schuifdeursysteem-voor-een-deur': '/pl/produkty/s120-system-do-drzwi-przesuwnych-na-1-skrzydlo-drzwi',
      '/nl/producten/s60-schuifdeursysteem-voor-een-deur': '/pl/produkty/s60-system-do-drzwi-przesuwnych-na-1-skrzydlo-drzwi',
      '/nl/imprint': '/pl/imprint',
      '/nl/verzending-en-betaling': '/pl/koszty-dostawy',
      '/nl/data-protection': '/pl/polityka-prywatnosci',
      '/nl/cancellation': '/pl/odstapienie-od-umowy',
      '/nl/bedrijf': '/pl/o-firmie',
      '/nl/terms-and-conditions': '/pl/regulamin',
      '/nl/producten': '/pl/produkty',
}
